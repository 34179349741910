import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModal } from './state/confirmation-modal';

@Component({
    template: `
    <cui-card>
      <cui-card-body>
        <h2>{{ data.modalText }}</h2>
      </cui-card-body>
      <cui-card-footer>
        <cui-button (clicked)="onButtonClicked()">{{ data.buttonText }}</cui-button>
        <cui-button-cta (clicked)="onCtaClicked()">{{ data.ctaText }}</cui-button-cta>
      </cui-card-footer>
    </cui-card>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConfirmationModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationModal, private dialogRef: MatDialogRef<ConfirmationModal>) {}

  onCtaClicked(): void {
    this.dialogRef.close(true);
  }

  onButtonClicked(): void {
    this.dialogRef.close();
  }
}
