import { currentAddressCookieKey } from '@1clickfactory/common';
import { SubscribingComponent } from '@1clickfactory/common/base-component/subscribing.component';
import { Component, OnInit } from '@angular/core';
import { identitySelectors } from '@appState';
import { select, Store } from '@ngrx/store';
import { combineLatest, map, takeUntil } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import * as fromActions from '../../app-state/router-state/router.actions';

@Component({
    selector: 'ocf-after-signin',
    template: '',
    standalone: false
})
export class AfterSignInComponent extends SubscribingComponent implements OnInit {
  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    const currentAddressUrl = sessionStorage.getItem(currentAddressCookieKey);
    combineLatest([
      this.store.pipe(select(identitySelectors.selectIsAuthenticated)),
      this.store.pipe(select(identitySelectors.selectIsLoading)),
    ])
      .pipe(
        takeUntil(this.destroyed$),
        map(([isAuthenticated, isLoadingPermissions]) => ({ isAuthenticated, isLoadingPermissions })),
      )
      .subscribe(status => {
        if (status.isAuthenticated) {
          if (!currentAddressUrl || currentAddressUrl === '/') {
            this.store.dispatch(fromActions.goToReturnUrlOrHome());
          } else {
            this.store.dispatch(fromActions.goTo({ url: currentAddressUrl }));
          }
        } else {
          this.store.dispatch(fromActions.goTo({ url: currentAddressUrl ?? '' }));
        }
      });
  }
}
