import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { announcementsSelectors, identitySelectors } from '@appState';
import { select, Store } from '@ngrx/store';
import { DialogService, SubscribingBase } from 'cui-components';
import { filter, Observable, take } from 'rxjs';
import { Announcement } from 'src/app/app-state/announcements/announcements.model';
import { AppState } from 'src/app/app-state/app.store';
import { AnnouncementDialogComponent } from './announcement/announcement.component';

@Component({
    selector: 'ocf-home',
    styleUrls: ['./home.component.scss'],
    templateUrl: './home.component.html',
    imports: [RouterModule, CommonModule]
})
export class HomeComponent extends SubscribingBase implements OnInit {
  hasMMMenuAccess$: Observable<boolean> = this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.MM.MenuAccess'])));
  hasProjectMenuAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.Projects.MenuAccess'])),
  );
  hasSPMenuAccess$: Observable<boolean> = this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.SP.MenuAccess'])));
  hasSupportMenuAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.Support.MenuAccess'])),
  );
  hasInformationHubAndNewsAccess$: Observable<boolean> = this.store.pipe(
    select(
      identitySelectors.selectHasPermission(['Portal.Business.NewsArticles.MenuAccess', 'Portal.Business.InformationHub.MenuAccess'], true),
    ),
  );
  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogService: DialogService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.subscribe(
      this.store.pipe(
        select(announcementsSelectors.selectNotShownAnnouncements),
        filter(ann => !!ann.length),
        take(1),
      ),
      announcements => {
        if (announcements.length > 1) {
          this.openDialogsSequentially(announcements, 0);
        } else {
          this.dialogService.openDialog<{ announcement: Announcement }, AnnouncementDialogComponent>(
            AnnouncementDialogComponent,
            {
              announcement: announcements[0],
            },
            { autoFocus: false },
          );
        }
      },
    );
  }
  private openDialogsSequentially(announcements: Announcement[], currentIndex: number): void {
    if (currentIndex < announcements.length) {
      const currentAnnouncement = announcements[currentIndex];
      const dialogRef = this.dialogService.openDialog<{ announcement: Announcement }, AnnouncementDialogComponent>(
        AnnouncementDialogComponent,
        {
          announcement: currentAnnouncement,
        },
      );
      dialogRef.closed.subscribe(() => {
        this.openDialogsSequentially(announcements, currentIndex + 1);
      });
    }
  }
}
