import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationReloadNotificationComponent, NotificationsService } from 'cui-components';
import { filter } from 'rxjs';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  private previousUrl: string | null = null;
  private currentUrl: string | null = null;

  constructor(
    private loggingService: LoggingService,
    private readonly notifications: NotificationsService,
    private readonly zone: NgZone,
    private router: Router,
  ) {
    super();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;
      }
    });
  }

  handleError(error: Error): void {
    const chunkOrModuleLoadFail =
      /Loading chunk [\w\W]+ failed|ChunkLoadError|Failed to fetch dynamically imported module|Importing a module script failed/;
    // Jodit editor throws errors that are not relevant to the user
    // https://github.com/jodit/jodit-react/issues/134
    const unhandledJoditErrorMessages = ['Need URL for AJAX request', 'Node element must be in editor'];
    if (!navigator.onLine) {
      this.handleErrorNotifications('Your internet connection is unstable. Please reload the page with a stable connection.');
    } else if (chunkOrModuleLoadFail.test(error.message)) {
      this.handleErrorNotifications('The application has been updated. Reload the page to get newest changes.');
    } else if (unhandledJoditErrorMessages.includes(error.message)) {
      return;
    } else {
      console.error(error);
      this.loggingService.logException(error as Error, {
        message: error.message ?? 'No error message',
        currentUrl: this.currentUrl,
        previousUrl: this.previousUrl,
        userAgent: navigator.userAgent,
      });
    }
  }

  private handleErrorNotifications(message: string): void {
    this.zone.run(() => {
      this.notifications.showNotificationFromComponent(ApplicationReloadNotificationComponent, { data: { message }, duration: 10000 });
    });
  }
}
