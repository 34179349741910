import { Component, OnInit } from '@angular/core';
import { identityActions, impersonationActions } from '@appState';
import { Store } from '@ngrx/store';

@Component({
    selector: 'ocf-root',
    template: '<router-outlet></router-outlet>',
    standalone: false
})
export class MainComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.isPublicRoute) {
      this.store.dispatch(identityActions.checkAuth());
    }
    this.store.dispatch(impersonationActions.loadPartner());
  }

  private get isPublicRoute(): boolean {
    return document.location.pathname.includes('anonymous') || document.location.pathname.includes('agreements/');
  }
}
